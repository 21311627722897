import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import classNames from 'classnames';

//assets
import LoadsmartLogo from '@/public/images/logos/loadsmart_logotype.svg';

//styles
import {
  ContainerWrapper,
  MainContent,
  LogoWrapper,
  NavWrapper,
  NavContent,
  MobileMenuTrigger,
  MobileMenuAnimator,
  MobileMenuLabel,
  MenuItem,
  MenuButton,
  ItemLabel,
  Carret,
  SubmenuList,
  SubmenuLink,
  SubmenuLinkLabel,
  SubmenuLinkDesc,
} from './Navigation.styles';

const menuItems = [
  {
    label: 'Shippers',
    highlight: '/shipper/',
    cta: null,
    submenu: [
      {
        label: 'Shipper Solutions',
        description: `Instantly price, book, and ship with guaranteed capacity for FTL, LTL, PTL, Expedited, Drayage, and Multimodal.`,
        url: '/shipper/',
        external: false,
      },
      {
        label: 'Managed transportation',
        description: `Consulting services, end-to-end freight planning,
        procurement and execution services.`,
        url: '/shipper/managed-transportation/',
        external: false,
      },
      {
        label: 'ShipperGuide TMS',
        description: `Plan, procure and execute freight with the next generation Transportation Management System.`,
        url: '/shipper/shipperguide/',
        external: false,
      },
      {
        label: 'Private fleet optimizer',
        description: ` Match unused capacity with available spot and contract
                    demand.`,
        url: 'https://lp.loadsmart.com/loadsmart-fleet-optimization',
        external: true,
      },
      {
        label: 'Flatbed messenger',
        description: `Get access to premium capacity from top performing carriers.`,
        url: '/shipper/flatbed-messenger/',
        external: false,
      },
    ],
  },
  {
    label: 'Carriers',
    highlight: '/carrier/',
    cta: null,
    submenu: [
      {
        label: 'Carrier Solutions',
        description: `Find, book, and move more freight
from top shippers.`,
        url: '/carrier/',
        external: false,
      },
      {
        label: 'Carrier loadboard',
        description: `Find the right loads to grow your business.`,
        url: `${process.env.CARRIER_URL}/#/`,
        external: true,
      },
      {
        label: 'Carrier TMS',
        description: `Optimize dispatch. Reduce overhead costs. Scale fleets.`,
        url: '/carrier/tms/',
        external: false,
      },
      {
        label: 'Factoring',
        description: `No more emails, phone calls or whiteboards, create invoices
                    easily.`,
        url: 'https://lp.loadsmart.com/factoring-sign-up',
        external: true,
      },
    ],
  },
  {
    label: <>Docks &amp; Yards</>,
    highlight: '/warehouse/',
    cta: null,
    submenu: [
      {
        label: 'Dock & Yard Solutions',
        description: `Schedule, track, and measure performance for every dock appointment.`,
        url: '/warehouse/',
        external: false,
      },
      {
        label: 'Dock Scheduling',
        description: `Dock appointment scheduling software for warehouses and carriers.`,
        url: 'https://opendock.com/',
        external: true,
      },
      {
        label: 'Gate and Yard Visibility',
        description: `Camera-based asset tracking software for greater yard visibility.`,
        url: 'https://navtrac.com/',
        external: true,
      },
    ],
  },
  {
    label: 'About',
    highlight: '/about',
    cta: null,
    size: 'small',
    submenu: [
      {
        label: 'Who we are',
        url: '/about/',
        external: false,
      },
      {
        label: 'Careers',
        url: '/careers/',
        external: false,
      },
      {
        label: 'Partnerships',
        url: 'https://lp.loadsmart.com/loadsmart-partner-portal-1',
        external: true,
      },
      {
        label: 'Blog',
        url: 'https://blog.loadsmart.com/',
        external: true,
      },
      {
        label: 'Community',
        url: 'https://community.loadsmart.com/hc/en-us',
        external: true,
      },
    ],
  },
  {
    label: 'Contact',
    highlight: '/contact',
    cta: { url: '/contact/', external: false },
    submenu: [],
  },
  {
    label: 'Login',
    highlight: '/login',
    cta: null,
    size: 'small',
    submenu: [
      {
        label: 'Carriers',
        url: `${process.env.CARRIER_URL}/#/`,
        external: true,
      },
      {
        label: 'Shippers',
        url: `${process.env.SHIPPERGUIDE_URL}/login?utm_medium=web_site&utm_campaign=migrationsg`,
        external: true,
      },
      {
        label: 'All Products',
        url: '/login/',
        external: false,
      },
    ],
  },
  {
    label: 'Signup',
    highlight: '/signup',
    cta: null,
    size: 'small',
    submenu: [
      {
        label: 'Carriers',
        url: `${process.env.CARRIER_URL}/#/signup`,
        external: true,
      },
      {
        label: 'Shippers',
        url: `${process.env.SHIPPERGUIDE_URL}/signup`,
        external: true,
      },
      {
        label: 'All Products',
        url: '/login/',
        external: false,
      },
    ],
  },
];

export function Navigation({ theme }) {
  const router = useRouter();
  const [menuOpen, setMenuOpen] = useState(false);
  const [menuActiveIndex, setMenuActiveIndex] = useState(-1);

  const closeDropdownWhenClickOutside = () => {
    document.addEventListener('click', function (event) {
      const isClickInsideElement = document
        .getElementById('main-menu-wrapper')
        .contains(event.target);
      if (!isClickInsideElement) {
        setMenuOpen(false);
        setMenuActiveIndex(-1);
      }
    });
  };

  useEffect(() => {
    if (menuOpen || menuActiveIndex >= 0) {
      closeDropdownWhenClickOutside();
    }
  }, [menuOpen, menuActiveIndex]);

  return (
    <ContainerWrapper theme={theme}>
      <MainContent id="main-menu-wrapper">
        <LogoWrapper as="a" href="/" aria-label="Home">
          <LoadsmartLogo />
        </LogoWrapper>

        <NavWrapper className={menuOpen ? `open` : ``}>
          <NavContent>
            {menuItems.map((item, index) => {
              const keyNav = `navmenu-${item.label}-${index}`;

              var menuListClasses = classNames({
                open: menuActiveIndex === index,
                small: item?.size,
                highlight: router.pathname.includes(item.highlight),
              });

              return (
                <MenuItem key={keyNav}>
                  <MenuButton
                    as={item.submenu.length <= 0 ? 'a' : 'button'}
                    {...(item.cta?.url && { href: item.cta.url })}
                    {...(item.cta?.external && { target: '_blank' })}
                    {...(item.cta?.external && { rel: 'noreferrer noopener' })}
                    theme={theme}
                    onClick={() =>
                      setMenuActiveIndex(menuActiveIndex === index ? -1 : index)
                    }
                    className={menuListClasses}
                  >
                    <ItemLabel>{item.label}</ItemLabel>
                    {!item.submenu.length <= 0 && (
                      <Carret width="16" height="16" theme={theme} />
                    )}
                  </MenuButton>
                  {!item.submenu.length <= 0 && (
                    <SubmenuList className={menuListClasses}>
                      {item.submenu.map((subitem, subindex) => {
                        const keySubNav = `navsubmenu-${item.label}-${subindex}`;

                        var submenuLinkClasses = classNames({
                          bold: !subitem.description,
                          highlight: router.pathname === subitem.url,
                        });

                        return (
                          <SubmenuLink
                            key={keySubNav}
                            href={subitem.url}
                            {...(subitem?.external && { target: '_blank' })}
                            {...(subitem?.external && {
                              rel: 'noreferrer noopener',
                            })}
                            className={submenuLinkClasses}
                          >
                            <SubmenuLinkLabel className={submenuLinkClasses}>
                              {subitem.label}
                            </SubmenuLinkLabel>
                            {subitem.description && (
                              <SubmenuLinkDesc>
                                {subitem.description}
                              </SubmenuLinkDesc>
                            )}
                          </SubmenuLink>
                        );
                      })}
                    </SubmenuList>
                  )}
                </MenuItem>
              );
            })}
          </NavContent>
        </NavWrapper>

        <MobileMenuTrigger
          onClick={() => setMenuOpen(!menuOpen)}
          className={menuOpen ? `open` : ``}
          aria-labelledby="labeldiv"
        >
          <MobileMenuLabel id="labeldiv">Open Menu</MobileMenuLabel>
          <MobileMenuAnimator
            theme={theme}
            className={menuOpen ? `open` : ``}
            aria-hidden="true"
          >
            <span></span>
            <span></span>
            <span></span>
            <span></span>
          </MobileMenuAnimator>
        </MobileMenuTrigger>
      </MainContent>
    </ContainerWrapper>
  );
}
